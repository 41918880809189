// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*======================
    404 page
=======================*/


.page_404 {
    min-width: 100%;
    min-height: 100%;
    position: fixed;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.page_404 img {
    background-repeat: no-repeat;
}

.four_zero_four_bg {
    text-align: center;
    background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
    height: 500px;
    width: 700px;
    background-position: center;
    
}

.contant_box_404 {
    text-align: center;
    margin-top: -70px;
}

.contant_box_404 h1 {
    font-size: 80px;
}

.four_zero_four_bg h3 {
    font-size: 80px;
}

.link_404 {
    color: #fff !important;
    padding: 10px 20px;
    background: #39ac31;
    border-radius: 5px;
    margin: 20px 0;
    display: inline-block;
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/Notfound.css"],"names":[],"mappings":"AAAA;;wBAEwB;;;AAGxB;IACI,eAAe;IACf,gBAAgB;IAChB,eAAe;IACf,gBAAgB;IAChB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,kBAAkB;IAClB,+FAA+F;IAC/F,aAAa;IACb,YAAY;IACZ,2BAA2B;;AAE/B;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,mBAAmB;IACnB,kBAAkB;IAClB,cAAc;IACd,qBAAqB;AACzB","sourcesContent":["/*======================\n    404 page\n=======================*/\n\n\n.page_404 {\n    min-width: 100%;\n    min-height: 100%;\n    position: fixed;\n    background: #fff;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.page_404 img {\n    background-repeat: no-repeat;\n}\n\n.four_zero_four_bg {\n    text-align: center;\n    background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);\n    height: 500px;\n    width: 700px;\n    background-position: center;\n    \n}\n\n.contant_box_404 {\n    text-align: center;\n    margin-top: -70px;\n}\n\n.contant_box_404 h1 {\n    font-size: 80px;\n}\n\n.four_zero_four_bg h3 {\n    font-size: 80px;\n}\n\n.link_404 {\n    color: #fff !important;\n    padding: 10px 20px;\n    background: #39ac31;\n    border-radius: 5px;\n    margin: 20px 0;\n    display: inline-block;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
